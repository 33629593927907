<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-palette-swatch-outline</v-icon>
        Relatórios
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title class="d-flex flex-column gap">
            <div
              class="align-end d-flex flex-row gap justify-end"
              style="width: 100%"
            >
              <v-btn color="xbColor" class="white--text" @click="openPDF">
                <v-icon left>mdi-file-outline</v-icon>
                PDF
              </v-btn>
            </div>

            <div
              class="align-end d-flex flex-row gap justify-start"
              style="width: 100%"
            >
              <v-text-field
                style="max-width: 400px"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                clearable
                dense
                hide-details
              ></v-text-field>

              <DataField
                :data_sync.sync="searchDateInit"
                label="Data Início"
                dense
                hide-details
                style="max-width: 400px"
              />

              <DataField
                :data_sync.sync="searchDateEnd"
                label="Data Fim"
                dense
                hide-details
                style="max-width: 400px"
              />

              <v-select
                v-model="cliente"
                :items="clientes"
                label="Cliente"
                hide-details
                clearable
                dense
                style="max-width: 400px"
                item-value="id"
                item-text="value"
              >
              </v-select>

              <v-checkbox
                v-model="vip"
                label="Cliente VIP"
                class="align-self-end"
                color="xbColor"
                hide-details
                style="white-space: nowrap"
              ></v-checkbox>
            </div>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="pedidosFiltered"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables"
            >
              <!-- ID -->
              <template v-slot:item.id="{ item }">
                {{ item.id }}
              </template>

              <!-- Data -->
              <template v-slot:item.data="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{
                    dateFormat(
                      new Date(item.data).toISOString(),
                      "dd/MM/yyyy 'às' hh:mm"
                    )
                  }}
                </div>
              </template>

              <!-- valor -->
              <template v-slot:item.valor_total="{ item }">
                <div class="d-flex align-center justify-space-between">
                  R$ {{ item.valor_total | currency }}
                </div>
              </template>

              <!-- tipo -->
              <template v-slot:item.cliente.vip="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{ item.cliente.vip ? "VIP" : "Comum" }}
                </div>
              </template>

              <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        item.status === 0
                          ? 'orange darken-2'
                          : item.status === 1
                          ? 'green darken-2'
                          : 'yellow darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status === 0
                          ? "mdi-alert-circle"
                          : item.status === 1
                          ? "mdi-check-circle"
                          : "mdi-close"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{
                      item.status === 0
                        ? "Pendente"
                        : item.status === 1
                        ? "Entregue"
                        : "Cancelado"
                    }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { allPedidos } from "@/api/admin/loja.js";
import DataField from "@/components/fields/DataField.vue";
import { dateFormat } from "@/filters";
import { all } from "@/api/admin/usuarios";
import { generatePdf } from "@/pdfs/relatorioLoja";

export default {
  name: "relatorios",
  data() {
    return {
      loading: true,
      loadingDispositivos: false,
      pedidos: [],
      search: "",
      searchDateInit: null,
      searchDateEnd: null,
      vip: false,
      clientes: [],
      cliente: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          width: "80px",
        },
        {
          text: "Data",
          value: "data",
          width: "12rem",
        },
        {
          text: "Cliente",
          value: "cliente.nome",
        },
        {
          text: "Tipo",
          value: "cliente.vip",
        },
        {
          text: "Valor",
          value: "valor_total",
          width: "8rem",
        },
        {
          text: "Status",
          value: "status",
          width: "80px",
          sortable: false,
          align: "center",
        },
      ];
    },
    pedidosFiltered() {
      let pedidosFiltrados = this.pedidos;
      if (this.vip)
        pedidosFiltrados = pedidosFiltrados.filter((item) => item.cliente.vip);
      if (this.cliente)
        pedidosFiltrados = pedidosFiltrados.filter(
          (item) => item.cliente_id === this.cliente
        );
      if (this.searchDateInit)
        pedidosFiltrados = pedidosFiltrados.filter(
          (item) =>
            new Date(item.data).getTime() >=
            new Date(this.searchDateInit).getTime()
        );
      if (this.searchDateEnd)
        pedidosFiltrados = pedidosFiltrados.filter(
          (item) =>
            new Date(item.data).getTime() <=
            new Date(this.searchDateEnd).getTime()
        );
      return pedidosFiltrados;
    },
  },
  watch: {
    url: {
      handler() {
        this.getPedidos();
        this.getClientes();
      },
      immediate: true,
    },
  },
  methods: {
    getPedidos() {
      this.loading = true;
      allPedidos()
        .then((response) => {
          this.pedidos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dateFormat,
    openPDF() {
      let nomeCliente = null;
      if (this.cliente)
        nomeCliente = this.clientes.find(
          (item) => item.id === this.cliente
        )?.value;
      generatePdf(
        this.pedidosFiltered,
        this.searchDateInit,
        this.searchDateEnd,
        nomeCliente,
        this.vip
      );
    },

    getClientes() {
      all().then((response) => {
        this.clientes = response.map((item) => {
          return {
            id: item.id,
            value: item.nome,
          };
        });
      });
    },
  },
  components: { DataField },
};
</script>

<style scoped>
.gap {
  gap: 1rem;
}
</style>

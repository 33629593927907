import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/index.js";
import { format } from "date-fns";
import { header } from "./components/header";
import { title } from "./components/title";
import { contentTable } from "./components/content";

export function generatePdf(data, dateInit, dateEnd) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.perfil;
    let replaceDateInit = dateInit + " 12:00:00";
    let replaceDateEnd = dateEnd + " 12:00:00";
    const orderedList = data?.sort(
      (a, b) => new Date(a.data).getTime() - new Date(b.data).getTime()
    );
    if (!dateInit)
      replaceDateInit = orderedList[0]?.data || "2023-01-01 12:00:00";
    if (!dateEnd)
      replaceDateEnd =
        orderedList[orderedList.length - 1]?.data || new Date().toISOString();

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [0, 96, 0, 15],
      pageOrientation: "portrait",
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      header: header(),
      content: [title(replaceDateInit, replaceDateEnd), contentTable(data)],
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `Impresso por ${nome} as ${now}`,
                fontSize: 7,
                margin: [25, 0, 0, 0],
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 25, 0],
                alignment: "right",
                fontSize: 7,
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
      info: {
        title: "Relatório de vendas",
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}

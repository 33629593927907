import { logo } from "../base64/logo";
import { x } from "../base64/x";

export const header = () => {
  return [
    {
      image: "data:image/png;base64," + x,
      height: 50,
      width: 70,
      margin: [16, 16, 16, 0],
    },
    {
      image: "data:image/png;base64," + logo,
      height: 40,
      width: 160,
      margin: [90, -45, 16, 0],
    },
  ];
};

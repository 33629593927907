import { dateFormat } from "@/filters";
import { iconFile } from "../svgs/iconFile";

export const title = (dateInit, dateEnd) => {
  return {
    table: {
      headerRows: 1,
      widths: ["auto", "*"],
      body: [
        [
          {
            svg: iconFile,
            width: 18,
          },
          {
            text: "Relatório de Vendas",
            bold: true,
            fontSize: 16,
            color: "#3a3a3a",
          },
        ],
        [
          "",
          {
            text: `${
              dateInit
                ? "Início: " +
                  dateFormat(new Date(dateInit).toISOString(), "dd/MM/yyyy")
                : ""
            } ${dateInit && dateEnd ? "-" : ""} ${
              dateEnd
                ? "Fim: " +
                  dateFormat(new Date(dateEnd).toISOString(), "dd/MM/yyyy")
                : ""
            }`,
            fontSize: 10,
            color: "#3a3a3a",
            alignment: "right",
          },
        ],
      ],
    },
    layout: {
      hLineWidth: (i) => {
        if (i === 0 || i === 2) return 0;
        else return 2;
      },
      vLineWidth: () => {
        return 0;
      },
      hLineColor: () => {
        return "#bbbbbb";
      },
    },
    margin: [32, 0, 32, 0],
  };
};

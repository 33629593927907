import { dateFormat, formatCurrency } from "@/filters";

function separarEmIntervalos(array) {
  const resultado = [];

  let dataToSeparate = [];
  if (array.length > 30) {
    dataToSeparate = array.slice(30);
  }

  resultado.push(array.slice(0, 30));

  for (let i = 0; i < dataToSeparate.length; i += 35) {
    const subarray = dataToSeparate.slice(i, i + 35);
    resultado.push(subarray);
  }

  return resultado;
}

function createHeader() {
  return {
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 3,
      widths: [20, 120, "*", 60, 60, "auto"],

      body: [
        [
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
        ],
        [
          {
            text: "ID",
            fontSize: 10,
            alignment: "center",
            color: "#3a3a3a",
          },
          {
            text: "DATA",
            fontSize: 10,
            color: "#3a3a3a",
          },
          {
            text: "CLIENTE",
            fontSize: 10,
            color: "#3a3a3a",
          },
          {
            text: "TIPO",
            fontSize: 10,
            color: "#3a3a3a",
          },
          {
            text: "VALOR",
            fontSize: 10,
            color: "#3a3a3a",
          },
          {
            text: "STATUS",
            fontSize: 10,
            color: "#3a3a3a",
          },
        ],
        [
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
          {
            text: " ",
            fontSize: 5,
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i) {
        if (i === 1 || i === 2) return 0;
        else return 1;
      },
      vLineWidth: function () {
        return 0;
      },
      hLineColor: function () {
        return "#bbbbbb";
      },
    },
    margin: [32, 32, 32, 0],
  };
}

function createFooter(init, end, numberItens) {
  return {
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      headerRows: 2,
      widths: ["*"],

      body: [
        [
          {
            text: " ",
            fontSize: 5,
          },
        ],
        [
          {
            text: `${init} - ${end}, de ${numberItens} itens`,
            fontSize: 7,
            color: "#3a3a3a",
            alignment: "right",
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i) {
        if (i === 0 || i === 1) return 0;
        else return 1;
      },
      vLineWidth: function () {
        return 0;
      },
      hLineColor: function () {
        return "#bbbbbb";
      },
    },
    margin: [32, 0, 32, 0],
  };
}

export const contentTable = (data) => {
  const dataTable = separarEmIntervalos(data);

  const response = [];

  dataTable.forEach((table, index) => {
    let init = (index - 1) * 35 + 30 + 1;
    if (init < 0) init = 0;
    let end = init + table.length;
    if (index > 0) end--;
    const newTable = createHeader();
    const newFooter = createFooter(init, end, data.length);
    table.forEach((row) => {
      newTable.table.body.push([
        {
          text: row.id,
          fontSize: 10,
          alignment: "center",
          color: "#3a3a3a",
        },
        {
          text: dateFormat(
            new Date(row.data).toISOString(),
            "dd/MM/yyyy 'às' hh:mm"
          ),
          fontSize: 10,
          color: "#3a3a3a",
        },
        {
          text: row.cliente?.nome,
          fontSize: 10,
          color: "#3a3a3a",
        },
        {
          text: row.cliente?.vip ? "VIP" : "Comum",
          fontSize: 10,
          color: "#3a3a3a",
        },
        {
          text: "R$ " + formatCurrency(row.valor_total),
          fontSize: 10,
          color: "#3a3a3a",
        },
        {
          text:
            row.status === 0
              ? "Pendente"
              : row.status === 1
              ? "Entregue"
              : "Cancelado",
          fontSize: 10,
          color: "#3a3a3a",
        },
      ]);
    });

    console.log(newTable.table.body.length);

    response.push([newTable, newFooter]);
  });

  return response;
};
